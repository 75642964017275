import { Component } from 'react'
import * as S from './Modal.styles'
import { ButtonWithIcon } from '../base/ButtonWithIcon'
import {FontSize, LAYOUT_TEMPLATE} from '../../services/api/types'
import { PAGE_TEMPLATE } from '../../services/api/constants'
import * as R from '../blocks/ReadyStartedBlock.styles'
import { LazyImage } from '../lazy-image'

export type navigationState = 'fixed' | 'relative' | undefined

export interface ModalProps {
  buttonText?: string
  buttonColor?: string
  title?: string
  text?: string
  confirmText?: string
  onConfirm?: Function
  cancelText?: string
  onCancel?: Function
  navState?: navigationState
  isHeroButton?: boolean
  isMobileButton?: boolean
  iconImageUrl?: string
  largeIconImageUrl?: string
  showCloseIcon?: boolean
  template?: LAYOUT_TEMPLATE
  className?: string
  buttonSize?: FontSize
}

export class Modal extends Component<ModalProps, any, any> {
  constructor(props: ModalProps) {
    super(props)
    this.state = { show: false }
  }

  setShow = (isShow: boolean) => {
    this.setState({
      show: isShow
    })
  }

  render() {
    const { show } = this.state
    const {
      buttonText,
      title,
      text,
      confirmText,
      onConfirm,
      cancelText,
      onCancel,
      children,
      navState,
      isHeroButton,
      isMobileButton,
      largeIconImageUrl,
      iconImageUrl,
      buttonColor,
      buttonSize,
      showCloseIcon,
      template,
      className
    } = this.props

    return (
      <>
        {/* Button */}
        {buttonText ? (
          iconImageUrl ? (
            <ButtonWithIcon
              color={buttonColor ? buttonColor : 'blue'}
              iconImgUrl={iconImageUrl}
              label={buttonText}
              size= {buttonSize ? buttonSize : 'lg'}
              onClick={() => this.setShow(true)}
            ></ButtonWithIcon>
          ) : largeIconImageUrl ? (
            <R.LinkWrapper
                onClick={() => this.setShow(true)}
            >
              <R.ImgWrapper>
                <LazyImage src={largeIconImageUrl} alt={buttonText} />
              </R.ImgWrapper>
              <R.Text
                dangerouslySetInnerHTML={{ __html: buttonText }}
                
              ></R.Text>
            </R.LinkWrapper>
          ) : (
            <>
              {template === PAGE_TEMPLATE.YOU_FITNESS ? (
                <S.ModalHeadButtonYouFitness
                  navState={navState}
                  onClick={() => this.setShow(true)}
                >
                  {buttonText}
                </S.ModalHeadButtonYouFitness>
              ) : (
                <S.ModalHeadButton
                  navState={navState}
                  onClick={() => this.setShow(true)}
                  isHeroButton={isHeroButton}
                  isMobileButton={isMobileButton}
                >
                  {buttonText}
                </S.ModalHeadButton>
              )}
            </>
          )
        ) : null}
        {}

        {/* Modal */}
        <S.OverlayModal
          isOpen={show}
          onBackgroundClick={() => this.setShow(!show)}
          onEscapeKeydown={() => this.setShow(!show)}
          aria-labelledby='modal-label'
          //@ts-ignore
          template={template}
        >
          {(title || showCloseIcon) && (
            <S.ModalHeader showUnderline={!!title}>
              {title && <S.ModalTitle id='modal-label'>{title}</S.ModalTitle>}
              {showCloseIcon && (
                <S.ModalCloseIcon
                  template={template}
                  onClick={() => {
                    onCancel && onCancel()
                    this.setShow(false)
                  }}
                >
                  &times;
                </S.ModalCloseIcon>
              )}
            </S.ModalHeader>
          )}
          {text || children ? (
            <S.ModalBody className={className}>
              {text && <S.ModalText>{text}</S.ModalText>}
              {children}
            </S.ModalBody>
          ) : null}
          {confirmText || cancelText ? (
            <S.ModalFooter>
              {confirmText && (
                <S.ModalButton
                  color={
                    template === PAGE_TEMPLATE.YOU_FITNESS
                      ? 'blue-deep'
                      : 'blue'
                  }
                  type='button'
                  className='btn-ok'
                  onClick={() => {
                    onConfirm && onConfirm()
                    this.setShow(false)
                  }}
                >
                  {confirmText}
                </S.ModalButton>
              )}
              {cancelText && (
                <S.ModalButton
                  color={
                    template === PAGE_TEMPLATE.YOU_FITNESS ? 'white' : 'grey'
                  }
                  className='btn-cancel'
                  type='button'
                  onClick={() => {
                    onCancel && onCancel()
                    this.setShow(false)
                  }}
                >
                  {cancelText}
                </S.ModalButton>
              )}
            </S.ModalFooter>
          ) : null}
        </S.OverlayModal>
      </>
    )
  }
}

export default Modal
