import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import TextInput from './TextInput'
import { InputHTMLAttributes } from 'react'

const CheckBoxInput = styled(TextInput)`
  min-width: 40px;
`
const Label = styled.span<{ labelColor?: string }>`
  ${tw`ml-2 `}

  ${props =>
    props.labelColor &&
    props.labelColor == 'white' &&
    css`
      ${tw`text-white`}
    `}
`
const SquareBox = styled.span`
  ${tw`border-2 border-white hidden`}
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
`

const FormLabel = styled.label`
  ${tw`text-blue__dark font-medium flex items-center cursor-pointer`}
  &.goals-page {
    width:200px;
    display:inline;
    margin:10px;
    ${Label} {
      ${tw`leading-snug`}
      vertical-align:top;
      color:white
    }


    ${CheckBoxInput} {
      max-height:20px;

      &:checked ~ ${SquareBox} {
        ${tw`relative`}

        &:after {
          ${tw`absolute border border-2 border-white inline-block h-4 w-2`}
          border-top: none;
          border-left: none;
          content: ' ';
          transform: rotate(45deg);
          left: 9px;
          top: 2px;
        }
      }
    }   
  }

  &.you-fitness {
    ${Label} {
      ${tw`leading-snug`}
    }

    ${SquareBox} {
      ${tw`inline-block`}
    }

    ${CheckBoxInput} {
      ${tw`hidden`}

      &:checked ~ ${SquareBox} {
        ${tw`relative`}

        &:after {
          ${tw`absolute border border-2 border-white inline-block h-4 w-2`}
          border-top: none;
          border-left: none;
          content: ' ';
          transform: rotate(45deg);
          left: 9px;
          top: 2px;
        }
      }
    }
  }
`

interface CheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  borderColor?: 'white' | 'orange' | 'black' | 'red'
  labelColor?: string
}
export const CheckBox = (props: CheckBoxProps) => {
  const { value, label, className, labelColor, ...other } = props
  return (
    <FormLabel className={className}>
      <CheckBoxInput value={value} {...other} type='checkbox' />
      <SquareBox />
      <Label labelColor={labelColor} className=''>
        {label}
      </Label>
    </FormLabel>
  )
}
export default CheckBox
